<template>
  <div class="z-50 w-full md:inset-0 w-full overflow-y-auto overflow-x-hidden">
    <div class="relative w-full h-full">
      <!-- Modal content -->
      <form @submit.prevent="onSubmit">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <!-- Modal header -->
          <div
            class="
              flex
              justify-between
              items-start
              p-4
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              Revise Cash Collection ({{ selected.created_by }}) -
              {{ selected.date }}
            </h3>
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              data-modal-toggle="defaultModal"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-6 space-y-6">
            <div>
              <div class="w-full flex justify-between px-3 mb-6 md:mb-0">
                <div>
                  <label
                    class="
                      s
                      uppercase
                      tracking-wide
                      text-gray-700 text-xs
                      font-bold
                      mb-2
                    "
                    for="grid-zip"
                  >
                    branch
                  </label>
                  <h1 class="text-xl font-bold">
                    {{ selected.branch }}
                  </h1>
                </div>

                <div>
                  <label
                    class="
                      s
                      uppercase
                      tracking-wide
                      text-gray-700 text-xs
                      font-bold
                      mb-2
                    "
                    for="grid-zip"
                  >
                    Total total_sales
                  </label>
                  <h1 class="text-xl font-bold">
                    GMD {{ formatPrice(selected.total_sales) }}
                  </h1>
                </div>
              </div>

              <div class="w-full px-3 mb-6 md:mb-0 mt-6">
                <label
                  class="
                    block
                    uppercase
                    tracking-wide
                    text-gray-700 text-xs
                    font-bold
                    mb-2
                  "
                  for="grid-zip"
                >
                  Amont Deposited
                </label>
                <input
                  class="
                    appearance-none
                    block
                    w-full
                    bg-gray-200
                    text-gray-700
                    border border-gray-200
                    rounded
                    py-3
                    px-4
                    leading-tight
                    focus:outline-none focus:bg-white focus:border-gray-500
                  "
                  id="cashInHand"
                  type="number"
                  @paste.prevent
                  @keypress="isNumber($event)"
                  required
                  v-model="selected.total_deposited"
                />
              </div>

              <div class="w-full px-3 mb-6 md:mb-0 mt-6">
                <label
                  class="
                    block
                    uppercase
                    tracking-wide
                    text-gray-700 text-xs
                    font-bold
                    mb-2
                  "
                  for="grid-zip"
                >
                  comment
                </label>
                <textarea
                  class="
                    appearance-none
                    block
                    w-full
                    bg-gray-200
                    text-gray-700
                    border border-gray-200
                    rounded
                    py-3
                    px-4
                    leading-tight
                    focus:outline-none focus:bg-white focus:border-gray-500
                  "
                  required
                  v-model="comment"
                />
              </div>
            </div>

            <p v-if="commentError" class="text-red-500">
              Please add a comment to continue
            </p>

            <p
              class="
                text-base
                leading-relaxed
                text-gray-500
                dark:text-gray-400
                flex
                items-center
              "
            >
              <i class="bx bx-error text-3xl text-yellow-500"></i> You should
              only continue if you are sure of what you are doing
            </p>
          </div>
          <!-- Modal footer -->
          <div
            class="
              flex
              items-center
              p-6
              space-x-2
              rounded-b
              border-t border-gray-200
              dark:border-gray-600
            "
          >
            <button
              type="submit"
              :disabled="isLoading"
              class="
                text-white
                bg-blue-700
                hover:bg-blue-800
                focus:ring-4 focus:outline-none focus:ring-blue-300
                font-medium
                rounded-lg
                text-sm
                px-5
                py-2.5
                text-center
                dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
              "
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["selected"],

  data: () => ({
    isLoading: false,
    comment: "",

    commentError: false,
  }),

  methods: {
    async onSubmit() {
      // Submit the form
      if (this.comment == "") {
        this.commentError = true;
        return false;
      }

      this.isLoading = true;

      this.selected["comment"] = this.comment;

      try {
        await axios.patch("/revise/collection", this.selected);

        this.isLoading = false;
        this.$emit("complete");

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },

    getShortage() {
      if (!this.selected) {
        return false;
      }

      let total =
        Number(this.selected.total_deposits) +
        Number(this.selected.total_sales);

      let sum = total - Number(this.selected.cash_in_hand);

      if (sum < 0) {
        return 0;
      }
      return total - Number(this.selected.cash_in_hand);
    },

    getSurplus() {
      if (!this.selected) {
        return false;
      }

      let total =
        Number(this.selected.total_deposits) +
        Number(this.selected.total_sales);

      let sum = total;

      if (this.selected.cash_in_hand > sum) {
        return Math.abs(sum - this.selected.cash_in_hand);
      }

      return 0;
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>